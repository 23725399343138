import React, {
    Suspense,
    Fragment,
    lazy
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';

type Routes = {
    exact?: boolean;
    path?: string | string[];
    guard?: any;
    layout?: any;
    component?: any;
    routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes: Routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView'))
    },
    {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView/OrganizationPatientIdLogin'))
    },
    {
        exact: true,
        path: '/auth/:patient_id/:url?',
        component: lazy(() => import('src/views/auth/index'))
    },
    {
        exact: true,
        path: '/qr_auth/:action(register|login)/:organization_id/:url?',
        component: lazy(() => import('src/views/auth/QrAuth'))
    },
    {
        exact: true,
        path: '/prescription_auth/:patient_id/:prescription_public_id',
        component: lazy(() => import('src/views/authPrescription/index'))
    },
    {
        exact: true,
        path: '/login/patient/:patient_id',
        component: lazy(() => import('src/views/auth/LoginView/PatientIdLogin'))
    },
    {
        exact: true,
        path: '/login/:organization_id/:url?',
        component: lazy(() => import('src/views/auth/LoginView/OrganizationPatientIdLogin'))
    },
    {
        exact: true,
        path: '/login/patient/:patient_id/:url?',
        component: lazy(() => import('src/views/auth/LoginView/PatientIdLogin'))
    },
    {
        exact: true,
        path: '/register/',
        component: lazy(() => import('src/views/auth/RegisterView/index'))
    },
    {
        exact: true,
        path: '/register/:organization_id/:url?',
        component: lazy(() => import('src/views/auth/RegisterView/index'))
    },
    {
        exact: true,
        path: '/register/simple/:patient_id/:url?',
        component: lazy(() => import('src/views/auth/RegisterView/RegisterSimple'))
    },
    {
        exact: true,
        path: '/passwordRecovery/:organization_id',
        component: lazy(() => import('src/views/auth/PasswordRecoveryView/OrganizationPatientIdRecovery'))
    },
    {
        exact: true,
        path: '/passwordRecovery/simple/:patient_id',
        component: lazy(() => import('src/views/auth/PasswordRecoveryView/IdRecovery'))
    },
    {
        exact: true,
        path: '/reset/password/:token/:redirect_url?',
        component: lazy(() => import('src/views/auth/ResetPasswordView'))
    },
    {
        path: '/iframe/:token/:optional_params?',
        component: lazy(() => import('src/views/iframe/index'))
    },
    {
        exact: true,
        path: '/3D/:model_3d_id',
        component: lazy(() => import('src/views/models/ModelsDetailsView'))
    },
    {
        exact: true,
        path: '/eConsent/signature/:eConsent_public_id/:email_id/:DocGUI/:lang',
        component: lazy(() => import('src/views/eConsent/SignatureDialog'))
    },
    {
        exact: true,
        path: '/prescription/:prescription_public_id/:resource_sequence?',
        component: lazy(() => import('src/views/prescription/prescriptionOpenView')),
        layout: null,
    },
    {
        exact: true,
        path: '/finish/eConsent/:eConsent_public_id/:DOC_GUI/:isPrescriptionPublic/:redirect_url?',
        //example:/finish/eConsent/dc7c6985-5329-4a68-a44a-7ccfd941d13a/b1202273-efb1-408e-833d-257fb12399f0/+app+homepage+8d10fb99-74b3-4aa6-8c6e-2ed2cb2c000e&&2
        component: lazy(() => import('src/views/eConsent/FinishEConsentView'))
    },
    {
        exact: true,
        path: '/prescription_video/:prescription_video_id',
        component: lazy(() => import('src/views/prescription/prescription_video_card/videoOpenView')),
        layout: null,
    },
    {
        exact: true,
        path: '/prescription_file/:prescription_file_resource_id',
        component: lazy(() => import('src/views/prescription/prescription_file_resource_card/fileResourceOpenView')),
        layout: null,
    },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app/register',
                component: lazy(() => import('src/views/auth/RegisterView/index'))
            },
            {
                exact: true,
                path: '/app/homepage/:resource_url?', //example: b20d3a7a-9db6-49a1-a45c-bc0a5c65005b&&4
                component: lazy(() => import('src/views/homepage'))
            },
            {
                exact: true,
                path: '/app/configuration/:configuration_dialog?', //example: wearables
                component: lazy(() => import('src/views/homepage'))
            },
            {
                exact: true,
                path: '/app/prescription_public_id/:id/:sequence_in_prescription/:status/:goal_email_id',
                component: lazy(() => import('src/views/trajectories/trajectoryDetailsDialog/AcceptGoalIncrement'))
            },
            {
                exact: true,
                path: '/app/myResources',
                component: lazy(() => import('src/views/homepage'))
            },
            {
                exact: true,
                path: '/app/finish/eConsent/:eConsent_public_id/:DOC_GUI/:isPrescriptionPublic/:redirect_url?',
                //example:/app/finish/eConsent/dc7c6985-5329-4a68-a44a-7ccfd941d13a/b1202273-efb1-408e-833d-257fb12399f0/+app+homepage+8d10fb99-74b3-4aa6-8c6e-2ed2cb2c000e&&2
                component: lazy(() => import('src/views/eConsent/FinishEConsentView'))
            },
            {
                exact: true,
                path: '/app/self_prescribe/:prescription_template_id',
                component: lazy(() => import('src/views/selfPrescribe/index')),
                layout: null,
            },
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                exact: true,
                path: '/'
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

export default routes;
